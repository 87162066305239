import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import oracle from "../../images/ORACLE.png";
import sql from "../../images/SQL.png";
import amazon from "../../images/amazon.png";
import ibm from "../../images/IBM.png";
import cbank from "../../images/CloudBnq.png";
import agcrowd from "../../images/AgCrowd.png";
import symbox from "../../images/symbox.png";
import liradia from "../../images/liradia.png";
// import comment from '../../images/comment.png';
import ContactUs from "../../components/ContactUs";

const Databaseservices = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Database Services"
        description="It is this database administration that puts businesses
                        one step ahead of their counterparts, and it is this
                        database administration that can put you one step ahead
                        of your competition. This is also what we at iTelaSoft
                        are looking to provide you with."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">iTelaSoft Database Services</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid wrap-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pb-6 section-2">
                <div className="row">
                  <div className="col-12 mt-4 text-center p-2">
                    <div className="discription-two pb-5">
                      <p>
                        A solid and secure database is something no modern
                        business can do without. It doesn’t matter how big or
                        small the company is or what services or products the
                        business offers, having a secure and reliable database
                        is a criterion for every business looking to survive in
                        the very competitive world of business we have today.
                      </p>
                      <p>
                        It is not enough to have a solid and secure database,
                        however. While having a secure and well-structured place
                        to store business data in might be a great boon to any
                        business, the data stored remains essentially useless
                        without proper administration of this data. This proper
                        database administration is what transfers simple figures
                        lying on sheets of papers and computer screens, into
                        profit and improved sales.
                      </p>
                      <p>
                        It is this database administration that puts businesses
                        one step ahead of their counterparts, and it is this
                        database administration that can put you one step ahead
                        of your competition. This is also what we at iTelaSoft
                        are looking to provide you with.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 pb-lg-0 pb-md-5">
                    <h4>Are you a start-up or small business?</h4>
                    <div className="divider"></div>

                    <p className="discription-two pt-3">
                      Then certainly most of your energy and focus will be
                      running your core business and you may in seek of someone
                      who can assist with database expertise! We can take the
                      heavy lifting of managing your database.
                    </p>
                    <h4>Are you an enterprise?</h4>
                    <div className="divider"></div>
                    <p className="discription-two pt-3">
                      Rather than depending on inhouse Database Administrators,
                      use iTelaSoft’s database administration as a service. We
                      comply with strict SLAs, non disclosure and privacy
                      standards. We cater database services on demand, so that
                      lowering your overall cost of IT infrastructure ownership
                      on premise or in the cloud.
                    </p>
                  </div>
                  <div className="col-12 col-lg-6 pl-lg-6 pl-0">
                    <h4>Our Database Administrator Services</h4>
                    <div className="divider"></div>
                    <div className="discription-two list">
                      <ul className="p-2">
                        <li>Database provisioning and setup</li>
                        <li>Schema design and developer support</li>
                        <li>Database performance tuning</li>
                        <li>High availability setup</li>
                        <li>Data replication, clustering and redundancy</li>
                        <li>Backup, Restore and Disaster Recovery</li>
                        <li>Upgrade and Migration Services</li>
                        <li>License management</li>
                        <li>Database health monitoring</li>
                        <li>Proactive data/system maintenance</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="what-we-offer text-center">
                <div className="row">
                  <div className="col-12 pb-5">
                    <h1 className="page-title">What We Offer</h1>
                    <hr />
                    <p className="discription-two pl-lg-5 pr-lg-5 pl-md-3 pr-md-3 p-xs-0 ">
                      At iTelaSoft, we provide IT services and solutions to
                      clients all over Australia looking to bring their brands
                      and businesses to the forefront of their respective
                      industries. One of these such solutions is our set of
                      modern Database Administration (DBA) services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container wrap-content mt-5">
        <div className="row">
          <div className="col-12 pb-5">
            <h4 className="mb-3">Database Design and Architecture</h4>
            <p className="discription-two">
              very database can only be as good as it was designed to be. This
              is why we at iTelaSoft pay great attention to the development of
              each database. We make sure to take care of all the details of the
              database’s design and architecture with our team of experts making
              sure the database is designed to be as secure, organized, and
              efficient as possible.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pb-5">
            <h4 className="mb-3">Database Development</h4>
            <p className="discription-two">
              Since designing a database to be efficient, reliable, and secure
              and developing it to be just as effective, reliable, and secure in
              realtime are not the same thing, iTelaSoft also takes care of the
              database development process making sure the database is developed
              to be as effective as it was designed to be. Our experts carry out
              a series of debugging tests in order to make sure the database
              functions as should be. <br />
              Our databases are designed to be entirely user-friendly and easy
              to use, and our developers make sure to take note of this fact
              during development and thus create databases with maximum user
              experience
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pb-5">
            <h4 className="mb-3">Database Support</h4>
            <p className="discription-two">
              We do not simply design and develop databases, we also make sure
              to give you all the IT support you might need to run your database
              at the highest capacity. This include:
            </p>
            <div className="discription-two list">
              <ul className="pl-2 pr-2">
                <li>Database recovery and restore</li>
                <li>Database upgrade and migration</li>
                <li>Monitoring and general servicing</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="logo-set text-md-center">
              <img className="img-fluid" alt="oracle" src={oracle} />
              <img className="img-fluid" alt="sql" src={sql} />
              <img className="img-fluid" alt="amazon" src={amazon} />
              <img className="img-fluid" alt="ibm" src={ibm} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 mb-md-5">
            <h1 className="page-title pb-3">Databases Expertise</h1>
            <hr />
            <p className="discription-two pt-3">
              At iTelaSoft, we specialize in the following databases:
            </p>
            <h4 className="mb-2">Oracle</h4>
            <p className="discription-two mb-2">
              One of our biggest specialties, Oracle is a highly secure and
              reliable database framework with a great user interface.
            </p>
            <h4> Other databases include: </h4>
            <ul className="p-2 discription-two list">
              <li>SQL</li>
              <li>Amazon DynamoDB</li>
              <li>IBM</li>
            </ul>
          </div>
          <div className="col-md-12">
            <h1 className="page-title pb-3">Why Choose Us?</h1>
            <hr />
            <p className="discription-two">
              Our unique brand of DBA services provide you with the following
              benefits:
            </p>
            <ul className="p-2 discription-two list">
              <li>Maximum data security</li>
              <li>Scalability and upgradability</li>
              <li>Easy to use user interface</li>
              <li>Adaptability and responsiveness</li>
              <li>High efficiency</li>
              <li>High ROI</li>
            </ul>
            <p className="discription-two">
              Looking to take your database technology to the next level? Then
              our database services are meant for you. This fact is doubly so if
              your business belongs to any of the following sectors:
            </p>
            <ul className="p-2 discription-two list">
              <li>Banking and Finance</li>
              <li>Telecommunications</li>
              <li>Education</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row wrap-our-partners">
        <div className="col-12 mt-4 text-center">
          <div className="our-cl-title">Our Clients</div>
          <div className="our-partners">
            <img className="img-fluid" alt="cbank" src={cbank} />
            <img className="img-fluid" alt="liradia" src={liradia} />
            <img className="img-fluid" alt="symbox" src={symbox} />
            <img className="img-fluid" alt="agcrowd" src={agcrowd} />
          </div>
        </div>
      </div>
      <div className="row wrap-contact-us">
        <ContactUs />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "database-services.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Databaseservices;
